import styled from 'styled-components'

const Container = styled.div`
  max-width: 100%;
  padding-left: 0px;
  padding-right: 0px;
  // ${({ theme }) => theme.mediaQueries.md} {
  //   padding-left: 24px;
  //   padding-right: 24px;
  // }
`

export default Container